<script>
import PageHeader from "@/components/PageHeader.vue";
import { PROVINCE_CODES} from "@/utils/config";
import pcaCode from "@/utils/pca-code";
import {developerCreate, developerInfo, developerUpdate, uploadFile} from "@/api/common";

export default {
  name: "EditDeveloper",
  components: {PageHeader},
  data(){
    return {
      id: null,
      pca_options: pcaCode,
      devPageForm: {
        name: '',
        unifiedCreditCode: '',
        phone: '',
        provinceCode: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
        legalMan: '',
        idNo: '',
        contacts: '',
        contactsPhone: '',
        dataId: '',
        secretKey: '',
        businessLicenseImg: '',
        no: '',
        ename: '',
        e: '',
        bankNo: '',
        bankName: '',
        bankJointNo: '',
      },
      //2024-05-16改  电话、商户编号、服务商密钥、服务商ID、电e宝账号名称、电e宝账号修改为非必填项
      devRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        unifiedCreditCode: [
          {required: true, message: '请输入统一信用代码', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        legalMan: [
          {required: true, message: '请输入法人', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证号', trigger: 'blur'},
        ],
        phone: [
          {required: false, message: '请输入电话', trigger: 'blur'},
        ],
        // no: [
        //   {required: true, message: '请输入商户编号', trigger: 'blur'},
        // ],
        // ename: [
        //   {required: true, message: '请输入电e宝名称', trigger: 'blur'},
        // ],
        // e: [
        //   {required: true, message: '请输入电e宝账户', trigger: 'blur'},
        // ],
        // dataId: [
        //   {required: true, message: '请输入服务商ID', trigger: 'blur'},
        // ],
        // secretKey: [
        //   {required: true, message: '请输入服务商密钥', trigger: 'blur'},
        // ],
        businessLicenseImg: [
          {required: true, message: '请上传营业执照', trigger: 'blur'},
        ],
      },
      page_form_loading: false,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getInfo()
    }
  },
  methods: {
    getInfo(){
      developerInfo(this.id).then(item => {
        this.devPageForm = {
          id: item.id,
          name: item.name,
          unifiedCreditCode: item.unifiedCreditCode,
          phone: item.phone,
          provinceCode: item.provinceCode,
          province: item.province,
          city: item.city,
          area: item.area,
          areas: [item.province, item.city, item.area],
          address: item.address,
          legalMan: item.legalMan,
          idNo: item.idNo,
          contacts: item.contacts,
          contactsPhone: item.contactsPhone,
          dataId: item.dataId,
          secretKey: item.secretKey,
          businessLicenseImg: item.businessLicenseImg,
          no: item.no,
          ename: item.ename,
          e: item.e,
          bankNo: item.bankNo,
          bankName: item.bankName,
          bankJointNo: item.bankJointNo,
          version: item.version,
        }
      })
    },
    handleThumbUpload(source, formName) {
      let that = this
      uploadFile(source.file).then(res => {
        if(formName === 'devPageForm'){
          that.devPageForm.businessLicenseImg = res
        }
        that.$forceUpdate();
      })
    },
    areaChange(val, formName) {
      this[formName].province = val[0]
      this[formName].city = val[1]
      this[formName].area = val[2]
      let company = PROVINCE_CODES.find(item => {
        return item.province == val[0]
      })
      if (company) {
        this[formName].provinceCode = company.id
      }
    },
    developerSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true
        let func
        if (this.id) {
          func = developerUpdate
        } else {
          func = developerCreate
        }

        func(that.devPageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.developer_dialog_visible = false
          setTimeout(() => {
            that.$router.replace('/admin/organization')
          }, 500)
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    cancel(){
      this.$router.replace('/admin/organization')
    }
  }
}
</script>

<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span>{{ $route.meta.title }}</span>
        </div>
        <div class="page-card-body">
          <el-form :model="devPageForm" :rules="devRules" label-width="120px" ref="developerForm"
                   inline label-position="top"
                   v-loading="page_form_loading">
            <el-row>
              <el-col :span="5">
                <el-form-item label="名称" prop="name" style="width: 100%;">
                  <el-input placeholder="名称" v-model="devPageForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="统一信用代码" prop="unifiedCreditCode" style="width: 100%;">
                  <el-input placeholder="统一信用代码" v-model="devPageForm.unifiedCreditCode" maxlength="20"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="电话" prop="phone" style="width: 100%;">
                  <el-input placeholder="电话" v-model="devPageForm.phone" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="地址" prop="areas" style="width: 100%;">
                  <el-cascader style="width: 100%;" ref="areaCascaderRef" v-model="devPageForm.areas"
                               :options="pca_options"
                               @change="areaChange($event, 'devPageForm')"
                               :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item style="width: 100%;" label="详细地址" prop="address">
                  <el-input v-model="devPageForm.address" placeholder="详细地址"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="法人" prop="legalMan">
                  <el-input v-model="devPageForm.legalMan" placeholder="法人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="法人身份证号码" prop="idNo">
                  <el-input v-model="devPageForm.idNo" placeholder="法人身份证号码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="商户编号" prop="no">
                  <el-input v-model="devPageForm.no" placeholder="商户编号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item style="width: 100%;" label="服务商密钥" prop="secretKey">
                  <el-input v-model="devPageForm.secretKey" placeholder="服务商密钥"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="服务商ID" prop="dataId">
                  <el-input v-model="devPageForm.dataId" placeholder="服务商ID"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="电e宝账号名称" prop="ename">
                  <el-input v-model="devPageForm.ename" placeholder="电e宝账号名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="电e宝账号" prop="e">
                  <el-input v-model="devPageForm.e" placeholder="电e宝账号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item style="width: 100%;" label="业务联系人电话" prop="contactsPhone">
                  <el-input v-model="devPageForm.contactsPhone" placeholder="业务联系人电话"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="业务联系人" prop="contacts">
                  <el-input v-model="devPageForm.contacts" placeholder="业务联系人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="银行账号" prop="bankNo">
                  <el-input v-model="devPageForm.bankNo" placeholder="银行账号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="银行联号" prop="bankJointNo">
                  <el-input v-model="devPageForm.bankJointNo" placeholder="银行联号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item label="营业执照" prop="businessLicenseImg">
                  <el-upload
                      action="-"
                      class="el-uploader"
                      style="--image-width: 320px;--image-height: 180px"
                      :show-file-list="false"
                      :http-request="(file) => handleThumbUpload(file,'devPageForm')">
                    <img v-if="devPageForm.businessLicenseImg" :src="devPageForm.businessLicenseImg | getSourceUri"
                         class="el-uploader-image">
                    <i v-else class="el-icon-plus el-uploader-icon"></i>
                    <div class="el-upload__tip" slot="tip">只能上传image文件 16:9</div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="开户银行" prop="bankName">
                  <el-input v-model="devPageForm.bankName" placeholder="开户银行"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">

              </el-col>
              <el-col :span="5" :offset="1">

              </el-col>
            </el-row>

            <div class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button @click="developerSubmit('developerForm')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-card-body {
  padding: 20px;

  .table-action-item {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;

    & + .table-action-item {
      margin-left: 10px;
    }
  }
}
</style>